/* eslint-disable */
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import DispatchNow from "./pages/dispatchNow";
import DeliveryEstimate from "./pages/deliveryEstimate";
import TrackDelivery from "./pages/trackDelivery";
import { useAuthenticateClientMutation } from "./services/slices/authSlice";
import { useEffect, useState } from "react";
import TrackDeliveryDetails from "./pages/trackDeliveryDetails";
import Import from "./pages/import";
import { Privacy } from "./pages/privacy";
import { NotFound } from "./pages/notFound";
import { DelayInfoModal } from "./components/Modal";
import { isFridayAt2PM } from "./helper";
import LassraOrder from "./pages/lassra";
function App() {
  return (
    <BrowserRouter>
      <MyComponent />
    </BrowserRouter>
  );
}

function MyComponent() {
  const [getAccount] = useAuthenticateClientMutation();
  const [step, setStep] = useState(null);
  const [delayInfo, setDelayInfo] = useState(false);

  const [dataDetails, setDataDetails] = useState<any>([]);
  function checkPathnameIncludes(substring: string) {
    const currentPathname = window.location.pathname;
    return currentPathname.includes(substring);
  }
  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const requestPayload = {
          clientsPhone: `${process.env.REACT_APP_CLIENT_PHONE}`,
          clientsPassword: `${process.env.REACT_APP_CLIENT_PASSWORD}`,
          clientsID: Number(process.env.REACT_APP_CLIENT_ID),
        };

        const response = await getAccount(requestPayload).unwrap();

        if (response) {
          const { clientDetails, authDetails } = response;

          localStorage.setItem("fez-user", JSON.stringify(clientDetails));
          localStorage.setItem("token", authDetails?.authToken);
        }
      } catch (error) {
        console.error("Error during authentication:", error);
      }
    };

    authenticateUser();
  }, []);
  let date = new Date();

  useEffect(() => {
    if (
      isFridayAt2PM(date) &&
      (checkPathnameIncludes("ship-now") || checkPathnameIncludes("import"))
    ) {
      setDelayInfo(true);
    } else {
      setDelayInfo(false);
    }
  }, []);
  return (
    <div className={`w-full `}>
      <Routes>
        <Route path="/ship-now" element={<DispatchNow />} />
        <Route path="/delivery-estimate" element={<DeliveryEstimate />} />
        <Route
          path="/track-delivery"
          element={
            <TrackDelivery
              setStep={setStep}
              dataDetails={dataDetails}
              setDataDetails={setDataDetails}
            />
          }
        />
        <Route
          path="/schedule-delivery-of-lasrra-card"
          element={<LassraOrder />}
        />
        <Route path="/import" element={<Import />} />
        <Route path={`/privacy`} element={<Privacy />} />
        <Route path={"*"} element={<NotFound />} />
        <Route
          path={`/track-delivery/:orderNo`}
          element={
            <TrackDeliveryDetails
              step={step}
              setStep={setStep}
              dataDetails={dataDetails}
              setDataDetails={setDataDetails}
            />
          }
        />

        <Route path="/" element={<RootPage />} />
      </Routes>
      {delayInfo && <DelayInfoModal onClick={() => setDelayInfo(false)} />}

    </div>
  );
}

const RootPage = () => {
  window.location.href = "https://www.fezdelivery.co";
  return null;
};
export const PublicRoute = () => {
  const user: any = localStorage.getItem("token") !== null;
  return !user ? <Outlet /> : <Navigate to="/" />;
};

export const ProtectRoute = () => {
  const user: any = localStorage.getItem("token") === null;
  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default App;
